import React from 'react';
import styled from 'styled-components';

import {ReactComponent as UserIcon} from 'icons/user.svg';

const Icon = styled.div<AvatarProps>`
  width: ${(props) => props.avatarWidth || '30px'};

  svg {
    width: 100%;
    fill: ${(props) => props.theme.colors.primary};
  }
`;

interface AvatarProps {
  avatarWidth?: string;
}

const Avatar = (props: AvatarProps) => (
  <Icon {...props}>
    <UserIcon />
  </Icon>
);

export default Avatar;
