import React from 'react';

import {QrComponentProps, withQrScanner} from 'components/QrScanner';

import Loader from 'components/Loader/Loader';
import {ReactComponent as QrCodeIcon} from './icons/qr-code-detail.svg';

const ActivityScan = (props: QrComponentProps) => {
  const {openScanner, startListening, isLoading} = props;

  return (
    <>
      {isLoading ? (
        <Loader sm={true} color={'#222'} />
      ) : (
        <QrCodeIcon
          width="100%"
          onClick={() => {
            startListening();
            openScanner();
          }}
        />
      )}
    </>
  );
};

export default withQrScanner(ActivityScan);
