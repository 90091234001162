import {useCallback, useContext, useMemo} from 'react';
import {AuthContext, AuthObject} from 'contexts/AuthContext';
import {UserProfile} from 'services/user';
import {AccountType} from 'types/User';

type UserProfileData = {
  isGuest: boolean;
  userProfile: UserProfile;
  reloadUserProfile: () => Promise<void>;
  updateUserData: AuthObject['updateUserData'];
  userFullName: string;
};

export const useUserProfile = (): UserProfileData => {
  const {loadUserProfile, userProfile, updateUserData} =
    useContext(AuthContext);

  const reloadUserProfile = useCallback(async () => {
    await loadUserProfile({reload: true});
  }, [loadUserProfile]);

  const userFullName = useMemo(() => {
    if (!userProfile) {
      return '';
    }

    return [userProfile.user.firstname, userProfile.user.lastname]
      .filter(Boolean)
      .join(' ');
  }, [userProfile]);

  const isGuest = userProfile?.user.account_type !== AccountType.USER;

  return {
    isGuest,
    reloadUserProfile,
    userFullName,
    updateUserData,
    userProfile: userProfile!,
  };
};
