import React, {useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';

import {numberToKm} from 'utils/numbers';
import {addBoldText} from 'utils/theme';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import useCustomActivity, {
  OnActivityComplete,
} from 'hooks/services/use-custom-activity';
import {CustomActivity} from 'types/CustomActivity';

import HeaderText from 'components/Text/HeaderText/HeaderText';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Loader from 'components/Loader/Loader';
import ActivityScan from 'components/CustomActivities/ActivityScan';

import {ReactComponent as CloseIcon} from 'icons/close.svg';
import {ReactComponent as CheckIcon} from './icons/check-detail.svg';

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 40000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  flex-direction: column;
  justify-content: space-between;
`;

const Body = styled.div`
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 20px 25px 10px;

  a {
    word-break: break-all;
  }
`;

const Icon = styled.div`
  width: 21px;
  margin-bottom: 20px;
`;

const Title = styled(HeaderText)`
  display: block;
  font-family: ${(props) => props.theme.font.secondary.name};
  text-align: center;
  margin-bottom: 20px;
  color: ${(props) =>
    props.theme.components.customActivities?.detailsHeaderColor};
`;

const Content = styled.div`
  overflow-y: scroll;
`;

const Footer = styled.div`
  background: ${(props) =>
    props.theme.components.customActivities?.detailsFooterBgColor || 'white'};
  color: ${(props) =>
    props.theme.components.customActivities?.detailsFooterColor ||
    props.theme.colors.black};
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 15px 25px 27px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
`;

const FooterContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const CtaIcon = styled.div<{isCompleted: boolean}>`
  fill: ${(props) =>
    props.isCompleted
      ? props.theme.components.customActivities?.ctaIconCheckColor ||
        props.theme.colors.primary
      : '#cacaca'};
  width: 40px;
  margin-right: 20px;
`;

const TextWrapper = styled.div`
  margin-bottom: 2px;
  display: block;
  width: 100%;
`;

const Text = styled(CopyTextSm)`
  margin-bottom: 2px;
  display: inline-block;
`;

const BoldText = styled(CopyTextSm)`
  margin-right: 4px;
  display: inline-block;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

type ActivityDetailsProps = {
  activity: CustomActivity;
  onClose: () => void;
  onSuccess: OnActivityComplete;
};

const ActivityDetails = (props: ActivityDetailsProps) => {
  const {activity, onClose, onSuccess} = props;
  const {translate} = useTranslations();
  const {updateBg} = useBodyBg({type: 'light'});
  const {getShortTokenText} = useTokenTranslation();

  const {isCompleting, performActivity, SuccessPrompt, DialogPrompt} =
    useCustomActivity({
      activity,
      onActivityComplete: onSuccess,
    });

  const co2Savings = useMemo(
    () => numberToKm({number: activity.co2_avoided}),
    [activity],
  );

  useEffect(() => {
    return () => updateBg({type: 'primary'});
  }, [updateBg]);

  const showQRCode = activity.qr_code && !activity.completed;

  return (
    <>
      {createPortal(
        <Wrapper>
          <Body>
            <Icon onClick={onClose}>
              <CloseIcon />
            </Icon>
            <Title>{activity.name}</Title>
            <Content dangerouslySetInnerHTML={{__html: activity.editorial}} />
          </Body>
          <Footer>
            <FooterContent>
              <CtaIcon
                onClick={() => {
                  if (showQRCode || activity.completed) {
                    return;
                  }
                  performActivity();
                }}
                isCompleted={activity.completed}>
                {isCompleting ? (
                  <Loader sm={true} color={'#222'} />
                ) : showQRCode ? (
                  <ActivityScan
                    onComplete={onSuccess}
                    onClose={onClose}
                    preventListen={true}
                  />
                ) : (
                  <CheckIcon width="100%" />
                )}
              </CtaIcon>
              {!activity.qr_code && (
                <TextWrapper>
                  {!!activity.recoins && (
                    <div>
                      <BoldText>{activity.recoins}</BoldText>
                      <Text>{getShortTokenText(activity.recoins)}</Text>
                    </div>
                  )}
                  {!!activity.co2_avoided && (
                    <div>
                      <BoldText>{co2Savings} kg</BoldText>
                      <Text>{translate('sdk.web.ca.co2Savings')}</Text>
                    </div>
                  )}
                </TextWrapper>
              )}
            </FooterContent>
          </Footer>
        </Wrapper>,
        document.body,
      )}
      <SuccessPrompt onClose={onClose} />
      <DialogPrompt />
    </>
  );
};

export default ActivityDetails;
