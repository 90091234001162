import {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {NATIVE_EVENTS} from 'constants/native-events';
import {AuthContext} from 'contexts/AuthContext';
import {SettingsContext} from 'contexts/SettingsContext';
import {useNativeListener} from 'hooks/use-native-listener';
import {useUserProfile} from 'hooks/use-user-profile';

import {NewToken} from 'types/NativeEvents';
import {setDelay} from 'utils/timer';

const TokenLoader = () => {
  const {push} = useHistory();
  const {isOnboardingComplete} = useContext(SettingsContext);
  const {reloadUserProfile} = useUserProfile();
  const {updateAuthToken} = useContext(AuthContext);

  const handleNewToken = useCallback(
    async (details: NewToken) => {
      console.log('New token received from token loader', details.new_token);
      updateAuthToken({token: details.new_token});
      await setDelay(300);
      await reloadUserProfile();
      push(routes.SETTINGS.ACCOUNT.href);
    },
    [push, reloadUserProfile, updateAuthToken],
  );

  useNativeListener({
    event: NATIVE_EVENTS.NEW_TOKEN,
    callback: handleNewToken,
    preventListen: !isOnboardingComplete,
  });

  return null;
};

export default TokenLoader;
