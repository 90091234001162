import React, {useContext, useMemo} from 'react';
import styled, {withTheme} from 'styled-components';
import {NavLink} from 'react-router-dom';

import routes from 'config/routes';
import {NotificationContext} from 'contexts/NotificationContext';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import useNotifications from 'hooks/services/notifications/use-notifications';
import {useUserProfile} from 'hooks/use-user-profile';
import {closeApp} from 'services/native-api';

import TextXs from 'components/Text/TextXs/TextXs';
import CloseIcon from 'components/CloseIcon/CloseIcon';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

import {ReactComponent as OffersIcon} from './images/rewards.svg';
import {ReactComponent as TopTeamsIcon} from './images/teams.svg';
import {ReactComponent as ImpactIcon} from './images/impact.svg';
import {ReactComponent as NewsIcon} from './images/news.svg';
import {ReactComponent as MoreIcon} from './images/more.svg';
import {ReactComponent as UserIcon} from './images/user.svg';

import {ReactComponent as LineOffersIcon} from './images/line/rewards.svg';
import {ReactComponent as LineMoreIcon} from './images/line/more.svg';
import {ReactComponent as LineTopTeamsIcon} from './images/line/teams.svg';
import {ReactComponent as LineImpactIcon} from './images/line/impact.svg';
import {ReactComponent as LineUserIcon} from './images/line/user.svg';

const MAX_NOTIFICATIONS = 9;

const getIconAttribute = () => (props: any) =>
  props.theme.components.nav.useLineIcon ? 'stroke' : 'fill';

const NavigationWrapper = styled.div`
  background: ${(props) =>
    props.theme.components.nav.bgColor ||
    props.theme.colors.body ||
    props.theme.colors.primary};
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px 0 13px;
`;

const MenuWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  display: flex;
`;

export const Menu = styled(NavLink)`
  position: relative;
  display: block;
  text-align: center;
  width: 50%;
  ${getIconAttribute()}: ${(props) =>
    props.theme.components.nav.color || props.theme.colors.white};
  color: ${(props) =>
    props.theme.components.nav.color || props.theme.colors.white};
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;

  &:hover {
    text-decoration: none;
    ${getIconAttribute()}: ${(props) =>
      props.theme.components.nav.color || props.theme.colors.white};
    color: ${(props) =>
      props.theme.components.nav.color || props.theme.colors.white};
  }

  > * {
    opacity: ${(props) => props.theme.components.nav.inactiveOpacity || 0.4};
  }

  &.active > * {
    opacity: 1;
    ${getIconAttribute()}: ${(props) => props.theme.components.nav.activeColor};
    color: ${(props) => props.theme.components.nav.activeColor};
  }
`;

export const MenuIcon = styled.div<{sm?: boolean}>`
  display: inline-block;
  width: ${(props) => (props.sm ? '30px' : '30px')};
  cursor: pointer;
  margin-bottom: 10px;
`;

export const CloseMenuIcon = styled.div`
  position: absolute;
  left: 2px;
  fill: ${(props) =>
    props.theme.components.nav.color || props.theme.colors.white};
  display: flex;
  width: 25px;
  cursor: pointer;
`;

export const Text = styled(CopyTextSm)`
  font-family: ${(props) => props.theme.font.secondary.name};
  display: block;
  font-weight: ${(props) => props.theme.components.nav?.fontWeight};
`;

export const UnseenNotifications = styled(TextXs)`
  position: absolute;
  top: -9.5px;
  left: 50%;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: ${(props) =>
    props.theme.components.notifications?.unreadCountBg || '#fcd921'};
  color: ${(props) =>
    props.theme.components.notifications?.unreadCountColor || '#222'};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(5px);
  opacity: 1;
  z-index: 1;
`;

const Navigation = (props: any) => {
  const {hideCloseBtn} = props;
  const {stats} = useContext(NotificationContext);
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {showNotifications} = useNotifications();
  const {isGuest} = useUserProfile();

  const translations = organizationConfig.translations?.nav;

  const homeRoutes = useMemo(() => {
    const useLineIcon = organizationConfig.theme.components.nav?.useLineIcon;

    return [
      {
        key: 'impact',
        translationKey: 'sdk.web.nav.your.impact',
        to: routes.DASHBOARD.CONTRIBUTIONS.href,
        Component: useLineIcon ? LineImpactIcon : ImpactIcon,
      },
      {
        key: 'teams',
        translationKey: 'sdk.web.nav.top.teams',
        to: routes.DASHBOARD.COMPETITION.href,
        Component: useLineIcon ? LineTopTeamsIcon : TopTeamsIcon,
      },
      {
        key: 'news',
        translationKey: 'sdk.web.nav.news',
        to: routes.DASHBOARD.NEWS_FEED.href,
        Component: NewsIcon,
        hidden: true,
      },
      {
        key: 'notifications',
        translationKey: 'sdk.web.nav.notifications',
        to: routes.DASHBOARD.NOTIFICATIONS.href,
        Component: NewsIcon,
        hidden: true,
      },
      {
        key: 'offers',
        translationKey: 'sdk.web.nav.offers',
        to: routes.DASHBOARD.REWARDS.href,
        Component: useLineIcon ? LineOffersIcon : OffersIcon,
      },
      {
        key: 'more',
        translationKey: 'sdk.web.nav.more',
        to: routes.SETTINGS.href,
        Component: useLineIcon
          ? isGuest
            ? LineMoreIcon
            : LineUserIcon
          : isGuest
          ? MoreIcon
          : UserIcon,
      },
    ];
  }, [organizationConfig, isGuest]);

  const parsedRoutes = useMemo(
    () =>
      homeRoutes
        .filter(
          ({hidden, key}) =>
            (organizationConfig.theme.components.nav?.routes
              ? organizationConfig.theme.components.nav.routes.includes(key)
              : !hidden) &&
            (key !== 'notifications' || showNotifications),
        )
        .map((route) => ({
          ...route,
          translationKey: translations?.[route.key] || route.translationKey,
        })),
    [homeRoutes, translations, organizationConfig, showNotifications],
  );

  return (
    <NavigationWrapper>
      {!hideCloseBtn && (
        <CloseMenuIcon>
          <MenuIcon sm onClick={closeApp}>
            <CloseIcon iconType="caret" />
          </MenuIcon>
        </CloseMenuIcon>
      )}
      <MenuWrapper>
        {parsedRoutes.map(({translationKey, to, key, Component}) => (
          <Menu key={key} to={to}>
            {['news', 'notifications'].includes(key) &&
              showNotifications &&
              stats?.unseen > 0 && (
                <UnseenNotifications>
                  <b>
                    {stats.unseen > MAX_NOTIFICATIONS
                      ? `${MAX_NOTIFICATIONS}+`
                      : stats.unseen}
                  </b>
                </UnseenNotifications>
              )}
            <MenuIcon>
              <Component />
            </MenuIcon>
            <Text>{translate(translationKey)}</Text>
          </Menu>
        ))}
      </MenuWrapper>
    </NavigationWrapper>
  );
};

export default withTheme(Navigation);
