import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as yup from 'yup';
import {useFormik} from 'formik';

import routes from 'config/routes';
import useAuthCheck from 'hooks/auth/use-auth-check';
import {useTranslations} from 'hooks/use-translations';
import {useToast} from 'hooks/use-toast';
import {useForm} from 'hooks/forms/use-form';
import {LoginData, validateEmail} from 'services/account';

import Input from 'components/Form/Input';
import InfoPage from 'components/InfoPage/InfoPage';
import AccountTitle from 'components/Account/AccountTitle';
import AccountSubtitle from 'components/Account/AccountSubtitle';
import FormRow from 'components/Form/FormRow';
import FormButton from 'components/Form/FormButton';
import AccountFooter from 'components/Account/AccountFooter';
import AccountButtonWrapper from 'components/Account/AccountButtonWrapper';
import AccountForm from 'components/Account/AccountForm';

const initialValues = {
  email: '',
  password: '',
};

const EmailValidationPage = () => {
  useAuthCheck();
  const formRef = useRef<HTMLFormElement>(null);
  const {translate} = useTranslations();
  const history = useHistory();
  const toast = useToast();
  const {getKeyDownListener} = useForm();
  const {push, goBack} = history;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(translate('sdk.web.validations.email.required'))
      .email(translate('sdk.web.contact.form.email.error.invalid')),
  });

  const onClose = () => {
    if (history.length > 2) {
      goBack();
    } else {
      push(routes.SETTINGS.href);
    }
  };

  const onSubmit = async (data: LoginData) => {
    setIsSubmitting(true);

    try {
      const response = await validateEmail(data);

      formik.resetForm();
      setIsSubmitting(false);

      push(
        response.registered
          ? routes.SETTINGS.LOGIN.href
          : routes.SETTINGS.REGISTER.href,
        {email: data.email},
      );
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const message = translate(
        errorKey || 'sdk.web.account.email.val.error.fallback',
      );
      toast.error(message);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <React.Fragment>
      <InfoPage
        title={translate('sdk.web.account.profile.title')}
        onClose={onClose}>
        <AccountForm onSubmit={formik.handleSubmit} ref={formRef}>
          <div>
            <AccountTitle>
              {translate('sdk.web.account.email.val.title')}
            </AccountTitle>
            <AccountSubtitle>
              {translate('sdk.web.account.email.val.subtitle')}
            </AccountSubtitle>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.email.label')}
                name={'email'}
                type={'email'}
                formik={formik}
                value={formik.values.email}
                onChange={formik.handleChange}
                onKeyDown={getKeyDownListener({form: formRef.current})}
                enterkeyhint={'go'}
              />
            </FormRow>
          </div>
          <AccountFooter>
            <AccountButtonWrapper>
              <FormButton isLoading={isSubmitting}>
                {translate('sdk.web.account.email.val.cta')}
              </FormButton>
            </AccountButtonWrapper>
          </AccountFooter>
        </AccountForm>
      </InfoPage>
    </React.Fragment>
  );
};

export default EmailValidationPage;
