import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {NATIVE_EVENTS} from 'constants/native-events';
import {QR_CODE_ACTIONS, QRCodeDetails} from 'constants/qr-codes';

import useAppAuthUpdate from 'hooks/auth/use-app-auth-update';
import useEvents from 'hooks/events/use-events';
import {useUserProfile} from 'hooks/use-user-profile';
import {useBoolean} from 'hooks/utils/use-boolean';
import {OnScan, QR_CODE_ERROR_KEY, useQrScanner} from 'hooks/use-qr-scanner';
import {useNativeListener} from 'hooks/use-native-listener';
import {useToast} from 'hooks/use-toast';
import {useTranslations} from 'hooks/use-translations';

import {completeActivity, getCustomActivity} from 'services/customActivity';
import {
  attachWallet,
  receiveCoins,
  ReceiveCoinsResponse,
} from 'services/wallets';
import {completeAccountTransfer} from 'services/migrations';
import {CustomActivity} from 'types/CustomActivity';
import {AccountType} from 'types/User';

import CaSuccessPrompt from 'components/CustomActivities/SuccessPrompt';
import PromptModal from 'components/PromptModal/PromptModal';
import WalletModal from 'components/QrScanner/WalletModal';
import QrPassword from 'components/QrScanner/QrPassword';
import DialogBox from 'components/DialogBox/DialogBox';
import CoinsReceivedPrompt from 'pages/Dashboard/WalletsPage/CoinsReceivedPrompt';

import bambooCoin from './images/bamboo-coin.svg';

export type QrComponentProps = {
  isLoading: boolean;
  openScanner: () => any;
  startListening: () => any;
};

type QrComponent = React.ComponentType<QrComponentProps>;

const defaultQrData = {
  id: '',
  qrCodeUrl: '',
};

interface QrScannerProps {
  qrEvent?: string;
  preventListen?: boolean;
  isOneTime?: boolean;
  onComplete?: (data?: any) => any;
  onClose?: () => any;
}

export const withQrScanner =
  (Component: QrComponent) => (props: QrScannerProps) => {
    const {
      qrEvent,
      onComplete,
      onClose,
      preventListen = true,
      isOneTime = true,
    } = props;
    const history = useHistory();

    const toast = useToast();
    const {translate} = useTranslations();
    const {onTokensUpdate} = useEvents();
    const {userProfile, reloadUserProfile} = useUserProfile();
    const {updateAppAuth} = useAppAuthUpdate();

    const [isCAPromptOpen, showCAPrompt, hideCAPrompt] = useBoolean(false);
    const [isCompletingAction, startCompletingAction, stopCompletingAction] =
      useBoolean(false);
    const [activity, setActivity] = useState<CustomActivity>();

    const [walletQrData, setWalletQrData] = useState(defaultQrData);
    const [coinsReceivedData, setCoinsReceivedData] = useState<
      ReceiveCoinsResponse | undefined
    >();
    const [errorMessage, setErrorMessage] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isWalletGuestWarningOpen, _, closeWalletGuestWarning] =
      useBoolean(false);
    const [isQrPasswordOpen, openQrPassword, closeQrPassword] =
      useBoolean(false);
    const [isLinkingWallet, startLinkingWallet, stopLinkingWallet] =
      useBoolean(false);

    const handleComplete = useCallback(
      (data: any) => onComplete && onComplete(data),
      [onComplete],
    );

    const handleCompleteActivity = useCallback(
      async (id: number | string) => {
        setActivity(undefined);

        try {
          const response = await completeActivity({token: id as string});
          const activity = await getCustomActivity({
            id: response.data[0]!.activity_id,
          });

          reloadUserProfile();
          setActivity(activity);
          showCAPrompt();

          handleComplete({activity});
        } catch (e: any) {
          const errorKey = e.response ? e.response?.data?.errorKey : '';
          const message = translate(
            errorKey || 'sdk.web.ca.complete.error.fallback',
          );
          toast.error(message);
        }
      },
      [toast, translate, reloadUserProfile, showCAPrompt, handleComplete],
    );

    const handleProcessAttachWallet = useCallback(
      async (data: {token: string; password: string}) => {
        try {
          startLinkingWallet();
          const response = await attachWallet(data);
          setWalletQrData((data) => ({
            ...data,
            qrCodeUrl: response.file_url,
          }));

          closeQrPassword();
          handleComplete(undefined);
        } catch (e: any) {
          const errorKey = e.response ? e.response?.data?.errorKey : '';
          const message = translate(
            errorKey || 'sdk.web.qr.attach.wallet.error.fallback',
          );
          toast.error(message);
        } finally {
          stopLinkingWallet();
        }
      },
      [
        toast,
        handleComplete,
        closeQrPassword,
        startLinkingWallet,
        stopLinkingWallet,
        translate,
      ],
    );

    const handleAttachWallet: OnScan = useCallback(
      async ({id, data}) => {
        if (userProfile.user.account_type === AccountType.GUEST) {
          // openWalletGuestWarning();
          // return;
        }

        setWalletQrData({
          id: id as string,
          qrCodeUrl: '',
        });

        if (data?.protected) {
          openQrPassword();
        } else {
          handleProcessAttachWallet({token: id as string, password: ''});
        }
      },
      [handleProcessAttachWallet, userProfile, openQrPassword],
    );

    const handleReceiveCoins = useCallback(
      async (token: string) => {
        try {
          const response = await receiveCoins({token});
          setCoinsReceivedData(response);
          reloadUserProfile();
          onTokensUpdate();
        } catch (e: any) {
          const errorKey = e.response ? e.response?.data?.errorKey : '';
          const message = translate(
            errorKey || 'sdk.web.qr.receive.coins.error.fallback',
          );
          setErrorMessage(message);
        }
      },
      [translate, reloadUserProfile, onTokensUpdate],
    );

    const handleMigrateUser = useCallback(
      async (data?: QRCodeDetails) => {
        if (!data?.payload.params.token) {
          return;
        }

        try {
          const transferData = await completeAccountTransfer({
            token: data.payload.params.token,
          });

          toast.success(translate('sdk.web.migration.process.success'));

          updateAppAuth({
            token: transferData.token.token,
            uuid: transferData.token.uuid,
          });
        } catch (e: any) {
          const errorKey = e.response ? e.response?.data?.errorKey : '';
          const message = translate(
            errorKey || 'sdk.web.migration.process.error.fallback',
          );
          toast.error(message);
        }
      },
      [toast, translate, updateAppAuth],
    );

    const handleScan: OnScan = useCallback(
      async ({id, action, data}) => {
        try {
          startCompletingAction();

          switch (action) {
            case QR_CODE_ACTIONS.CUSTOM_ACTIVITY:
              await handleCompleteActivity(id);
              break;
            case QR_CODE_ACTIONS.ATTACH_WALLET:
              await handleAttachWallet({id, data, action});
              break;
            case QR_CODE_ACTIONS.TRANSFER_COINS:
              const name = data!.metadata.name;
              const walletId = data!.payload.params.wallet_id;
              history.push(
                `${routes.DASHBOARD.WALLET.href}?scan=true&name=${name}&walletId=${walletId}`,
              );
              break;
            case QR_CODE_ACTIONS.SEND_COINS:
              await handleReceiveCoins(data!.payload.params.token);
              break;
            case QR_CODE_ACTIONS.MIGRATE_USER:
              await handleMigrateUser(data);
              break;
            default:
              const message = translate(QR_CODE_ERROR_KEY);
              toast.error(message);
          }
        } finally {
          stopCompletingAction();
        }
      },
      [
        toast,
        history,
        translate,
        startCompletingAction,
        stopCompletingAction,
        handleAttachWallet,
        handleReceiveCoins,
        handleCompleteActivity,
        handleMigrateUser,
      ],
    );

    const handleSetQrPassword = useCallback(
      (password: string) => {
        handleProcessAttachWallet({
          token: walletQrData.id,
          password,
        });
      },
      [walletQrData, handleProcessAttachWallet],
    );

    const {isLoading, openScanner, startListening, stopListening} =
      useQrScanner({
        preventListen,
        isOneTime,
        qrEvent,
        loadOffer: true,
        onScan: handleScan,
      });

    useNativeListener({
      callback: isOneTime ? stopListening : () => {},
      event: NATIVE_EVENTS.QRCODE_CLOSE,
    });

    return (
      <div>
        <Component
          isLoading={isLoading || isCompletingAction}
          openScanner={openScanner}
          startListening={startListening}
        />
        {activity && (
          <CaSuccessPrompt
            isVisible={isCAPromptOpen}
            onClose={() => {
              onClose && onClose();
              hideCAPrompt();
            }}
            activity={activity}
          />
        )}
        <QrPassword
          isVisible={isQrPasswordOpen}
          isSubmitting={isLinkingWallet}
          onSubmit={handleSetQrPassword}
          onClose={closeQrPassword}
        />
        {walletQrData.qrCodeUrl && (
          <WalletModal
            qrCode={walletQrData.qrCodeUrl}
            onClose={() => setWalletQrData(defaultQrData)}
          />
        )}
        <PromptModal
          isVisible={isWalletGuestWarningOpen}
          title={translate('sdk.web.qr.attach.wallet.guest.title')}
          textContent={translate('sdk.web.qr.attach.wallet.guest.text')}
          image={bambooCoin}
          btnText={translate('sdk.web.qr.attach.wallet.guest.cta')}
          onBtnClick={() => {
            closeWalletGuestWarning();
            history.push(routes.SETTINGS.ACCOUNT.href);
          }}
          moreInfo={{
            text: translate('sdk.web.qr.attach.wallet.guest.skip'),
            onClick: closeWalletGuestWarning,
          }}
          onClose={closeWalletGuestWarning}
        />
        <CoinsReceivedPrompt
          data={coinsReceivedData}
          onClose={() => setCoinsReceivedData(undefined)}
        />
        {errorMessage && (
          <DialogBox
            title={translate('sdk.web.qr.error.title')}
            promptMessage={errorMessage}
            singleText={translate('sdk.web.qr.error.cta')}
            onConfirmation={() => setErrorMessage('')}
          />
        )}
      </div>
    );
  };

const QrScanner = () => null;

export default withQrScanner(QrScanner);
